import { styled } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TagButton from "./TagButton";
import { useApi } from "./useFetch";
const { REACT_APP_API_HOST } = process.env;

const RootWrapper = styled("div")(() => ({
  maxWidth: "810px",
  height: "100%",
  background: "#141414",
  border: "1px solid #fff",
  padding: "50px",
}));

const SearchField = styled(TextField)(() => ({
  fontWeight: 300,
  marginTop: "50px",
  "&:after": {
    borderBottom: `2px solid red`,
  },
}));

export default function SideDrawer({ open, setOpen }) {
  const [text, setText] = useState();
  const [tagList, setTagList] = useState([]);
  const { state, data } = useApi(
    `${REACT_APP_API_HOST}/tags?filter={filter:''}`,
  );
  let navigate = useNavigate();
  useEffect(() => {
    state === "SUCCESS" &&
      setTagList(data.tags.filter((o) => o.is_main === true));
  }, [data, state]);

  const handleClose = () => {
    text &&
      navigate({
        pathname: `/search`,
        search: `filter={"filter":"${text}"}`,
      });
    setOpen(false);
  };
  return (
    <Drawer anchor={"right"} open={open} onClose={handleClose}>
      <RootWrapper>
        <SearchField
          placeholder="Search"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setText(e.target.value)}
        />
        <div>
          <TagButton name={"All"} big={true} />
          {tagList.map((e) => (
            <TagButton key={"tag-" + e.name} name={e.name} big={true} />
          ))}
        </div>
      </RootWrapper>
    </Drawer>
  );
}
